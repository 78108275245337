import React from 'react';
import { useTable, usePagination } from 'react-table';
import styled from 'styled-components';
import {
  StylesProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Button as UITKButton } from '@uitk/react';
import { useHistory } from 'react-router-dom';
import {
  PaginationActionButtonsWrapper,
  PaginationButtonsWrapper,
  PaginationWrapper,
  RawActionButton,
  TextWrapper,
  createButtons,
} from '../SiteDataTable/Style';
import InfoIcon from '../SiteDataTable/InfoIcon';
import { IconWrapper } from '../SiteDataTable/SiteDataTable';

const StyledButton = styled(UITKButton)`
  font-family: 'OptumSans';
  height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FormNameSpan = styled.span`
  font-weight: bold;
  font-family: 'OptumSans';
  font-size: 16px;
`;

const StyledSpan = styled.span`
  font-family: 'OptumSans';
  font-size: 16px;
  color: #8d91a0;
`;

const StyledBoldSpan = styled.span`
  font-family: 'OptumSans';
  font-weight: 400;
  color: #000;
  font-size: 16px;
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: x-large;
    font-family: OptumSans;
    font-weight: 700;
    border: '1px solid black',
    textAlign: 'left',
    & > input {
      font-family: OptumSans;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const FormDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  width: 40%;
`;

const FormDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const StyledTable = styled(Table)`
  border: 1px solid #d3d3d3;
  border-collapse: collapse;
  width: 98%;
`;
const StyledHeaderCell = styled(HeaderCell)`
  border: 1px solid #d3d3d3;
  padding: 8px;
  text-align: left;
  font-weight: bold;
  font-family: 'OptumSans';
  color: #002677;
  font-size: 25px;
`;

export interface FormTypeTableProps {
  formTypes: Array<FormTypesData>;
}

const FormTypesListTable = (props: FormTypeTableProps) => {
  const { formTypes } = props;
  const history = useHistory();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const handleViewSubmissions = (formId: number, formName: string) => {
    history.push({
      pathname: `/forms/${formId}`,
      state: { formName: formName, formId: formId },
    });
  };

  const data = React.useMemo(
    () =>
      formTypes.map(formType => {
        return {
          header: 'Your website forms with count zero',
          content: (
            <ContentWrapper>
              <FormDetailsWrapper>
                <FormDetailsRow>
                  <FormNameSpan>{formType.formName}</FormNameSpan>
                  <StyledSpan style={{ position: 'relative', left: '12%' }}>
                    URL - <StyledBoldSpan>{formType.siteUrl}</StyledBoldSpan>
                  </StyledSpan>
                </FormDetailsRow>
                <FormDetailsRow>
                  <StyledSpan>{`Created on ${new Date(
                    formType.createdTime
                  ).toLocaleDateString('en-US', options)} -`}</StyledSpan>
                  <span>&nbsp;</span>
                  <StyledBoldSpan>{formType.createdBy}</StyledBoldSpan>
                </FormDetailsRow>
              </FormDetailsWrapper>
              <div>
                <StyledButton
                  onClick={() =>
                    handleViewSubmissions(formType.formId, formType.formName)
                  }
                >
                  View Submissions
                </StyledButton>
              </div>
            </ContentWrapper>
          ),
        };
      }),
    [formTypes]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `Your website forms (${formTypes.length})`,
        accessor: 'content',
        Cell: ({ cell: { value } }) => value,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    usePagination
  );

  return (
    <StylesProvider injectFirst>
      <StyledTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledHeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                key={row.getRowProps().key}
                {...row.getRowProps()}
                data-test-id={`form-types-table-body-row-${i}`}
              >
                {row.cells.map(cell => {
                  return (
                    <TableCell
                      key={cell.getCellProps().key}
                      variant="body"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
      <PaginationWrapper>
        <PaginationButtonsWrapper>
          <TextWrapper isBold={true} reducePadding={true}>
            Entries per page
          </TextWrapper>
          <IconWrapper
            iconActive={false}
            tabIndex={0}
            data-test-id="info-wrapper"
          >
            <InfoIcon />
          </IconWrapper>
          {createButtons(3, setPageSize)}
        </PaginationButtonsWrapper>
        <PaginationActionButtonsWrapper>
          <TextWrapper isBold={true} reducePadding={true}>
            {`Displaying page: ${pageIndex + 1} of ${pageOptions.length} `}
          </TextWrapper>
          <RawActionButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            isPreviousButton={true}
            tertiary={false}
          >
            <TextWrapper reducePadding={true} isBold={false}>
              Previous
            </TextWrapper>
          </RawActionButton>
          <RawActionButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            tertiary={false}
            isPreviousButton={false}
          >
            <TextWrapper reducePadding={true} isBold={false}>
              Next
            </TextWrapper>
          </RawActionButton>
        </PaginationActionButtonsWrapper>
      </PaginationWrapper>
    </StylesProvider>
  );
};

export default FormTypesListTable;
