export const TEMPLATE_EDITOR_PAGE_ID = 99999999999999;
export const TEMPLATE_EDITOR_SITE_NAME = 'FAKE_SITE_FOR_TEMPLATE_EDITING';
export const TEMPLATE_STARTER_NAME = 'Template Starter';
// Identify the site builder is working on a template
// Templates need a site to be able to upload files to S3
// The site ID constant applies to the site builder groovy project
// the site ID must match the value in the groovy project
export const TEMPLATES_SITE_ID = 0;

export enum TEMPLATE_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}
