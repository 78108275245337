import {
  TEMPLATE_EDITOR_PAGE_ID,
  TEMPLATES_SITE_ID,
  TEMPLATE_EDITOR_SITE_NAME,
} from './constants';

export const siteSkeleton = {
  id: TEMPLATES_SITE_ID,
  name: TEMPLATE_EDITOR_SITE_NAME,
  domain: '',
  clientId: '',
  businessKey: '',
  productId: null,
  productName: null,
  tenantId: null,
  orgAccount: null,
  displayName: null,
  logo: null,
  theme: {
    font: {
      size: {
        body: {
          base: {
            value: 11,
          },
        },
      },
      weight: {
        base: {
          value: 'normal',
        },
      },
      family: {
        base: {
          value: 'OptumSans',
        },
      },
    },
    colors: {
      primary: '#FF612B',
      secondary: '#002667',
      tertiary: '#D9F6FA',
      quaternary: '#FBF9F4',
      custom: [
        {
          name: 'Brand 50',
          id: 'brand50',
          value: '#FF612B',
          type: 'Brand',
        },
        {
          name: 'Primary 100',
          id: 'primary100',
          value: '#002667',
          type: 'Brand',
        },
        {
          name: 'Primary 10',
          id: 'primary10',
          value: '#D9F6FA',
          type: 'Brand',
        },
        {
          name: 'Primary 5',
          id: 'primary5',
          value: '#FBF9F4',
          type: 'Brand',
        },
        {
          id: 'primary20',
          value: '#B0E3EA',
          name: 'Primary 20',
          type: 'Brand',
        },
        {
          id: 'primary70',
          value: '#0C55B8',
          name: 'Primary 70',
          type: 'Brand',
        },
        {
          id: 'primary90',
          value: '#003A8D',
          name: 'Primary 90',
          type: 'Brand',
        },
        {
          id: 'primary110',
          value: '#001D5B',
          name: 'Primary 110',
          type: 'Brand',
        },
        {
          id: 'primary120',
          value: '#00184D',
          name: 'Primary 120',
          type: 'Brand',
        },
        {
          id: 'accent01',
          value: '#EFE0FA',
          name: 'Accent 01',
          type: 'Brand',
        },
        {
          id: 'accent02',
          value: '#422C88',
          name: 'Accent 02',
          type: 'Brand',
        },
        {
          id: 'accent03',
          value: '#C83402',
          name: 'Accent 03',
          type: 'Brand',
        },
        {
          id: 'accent04',
          value: '#912400',
          name: 'Accent 04',
          type: 'Brand',
        },
      ],
    },
  },
  header: {
    variant: 'utilityWithNav',
    logoPosition: 'left',
    utilityBgColor: '',
    navBgColor: '',
    nav: {
      fontColor: '',
      items: [],
    },
    feedback: {
      feedbackEnable: false,
      formFields: [],
    },
  },
  postLogoutPage: {
    url: '',
    type: 'page',
    pageId: TEMPLATE_EDITOR_PAGE_ID,
  },
  footer: {
    variant: 'standard',
  },
  published: false,
  archived: false,
  autoSave: false,
  apps: [],
  homepageId: null,
  postLoginPageId: null,
  postLoginType: null,
  postLoginUrl: null,
  createdByUser: null,
  lastModifiedByUser: null,
  lastPublishedByUser: null,
  idpHint: '',
  aideId: null,
  siteProvisioningStores: [],
  isOwner: false,
  access: {
    read: true,
    edit: true,
    delete: true,
    create: true,
  },
  impersonationEnabled: false,
  horizonhomepageId: TEMPLATE_EDITOR_PAGE_ID,
  horizonpostLoginPageId: TEMPLATE_EDITOR_PAGE_ID,
  customWidgets: null,
  publishable: true,
  lastModifiedDate: 1733428791000,
  lastPublishedDate: null,
  tenancy: {
    tenantId: null,
  },
  prevSiteProvisioningStores: [],
  pages: {},
};
