import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
  RefObject,
  ReactNode,
} from 'react';
import { WidgetData, isSafari } from './utils';
import styled from 'styled-components';
import {
  FOOTER_PLACEHOLDER_ID,
  FOOTER_SECTION_ID,
  HEADER_PLACEHOLDER_ID,
  HEADER_SECTION_ID,
  widgetTypes,
} from '../../../builder/util/constants';

export interface W
  extends Pick<
    WidgetData,
    'id' | 'x' | 'y' | 'children' | 'fullWidth' | 'height'
  > {
  type: string;
}

interface PreviousBottoms {
  [key: string]: number;
}

interface WidgetProps {
  id: string;
  type: string;
  dynamic?: boolean;
  fullWidth?: boolean;
  isParentFullWidth?: boolean;
  parentId?: string;
  width: number;
  height: number;
  isSafariNav?: boolean;
  x: number;
  y: number;
  ref: React.RefObject<HTMLDivElement>;
  content: ReactNode | ReactNode[];
  headerChildrenIds: string[];
  footerChildrenIds: string[];
  visible?: boolean;
}

const Widget = styled.div<WidgetProps>`
    height: ${props => (props.isSafariNav ? `${props.height}px` : 'auto')};
    ${props =>
      props.type !== widgetTypes.RichText &&
      props.visible !== false &&
      `min-height: ${props.height}px;`}
  width: ${props => (props.fullWidth ? '100vw' : `100%`)};
  ${props => {
    if (!props.fullWidth) {
      let widthDivisor = 1280;
      if (
        props.isParentFullWidth ||
        ((props.footerChildrenIds.includes(props.id) ||
          props.headerChildrenIds.includes(props.id)) &&
          window.innerWidth >= 1280)
      ) {
        widthDivisor = window.innerWidth - (window.innerWidth - 1280);
      }
      if (
        props.footerChildrenIds.includes(props.id) ||
        props.headerChildrenIds.includes(props.id)
      ) {
        return `max-width: ${Math.min(
          (props.width / widthDivisor) * 100,
          (props.width / window.innerWidth) * 100
        )}%;`;
      } else {
        return `max-width: ${(props.width / widthDivisor) * 100}%;`;
      }
    }
  }}
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  ${props => {
    let widthDivisor =
      (props.isParentFullWidth || props.fullWidth) && window.innerWidth >= 1280
        ? window.innerWidth - (window.innerWidth - 1280)
        : 1280;
    if (
      props.footerChildrenIds.includes(props.id) ||
      props.headerChildrenIds.includes(props.id)
    ) {
      widthDivisor = window.innerWidth >= 1280 ? window.innerWidth : 1280;
    }
    return `left: ${(props.x / widthDivisor) * 100}%;`;
  }}
  top: ${props => `${props.y}px`};
`;

const PageLayout = ({ initialWidgets, currentPage }) => {
  const [widgets, setWidgets] = useState(initialWidgets);
  const [isLayoutUpdated, setIsLayoutUpdated] = useState(false);
  const [footerUpdated, setFooterUpdated] = useState(false);
  const originalPositionsRef = useRef(
    widgets.map((widget: WidgetData) => ({
      id: widget.id,
      height: widget.height,
      originalX: widget.x,
      originalY: widget.y,
    }))
  );
  // Store a reference to the original leftBoundingBox on initial page load. This is used for updating x positions of
  // header and footer items as the viewport size changes
  const leftBoundingBoxEdgeRef = useRef<number>(
    window.innerWidth >= 1280 ? (window.innerWidth - 1280) / 2 : 0
  );

  const widgetRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});

  useMemo(() => {
    widgetRefs.current = widgets.reduce(
      (acc: { [x: string]: React.RefObject<unknown> }, widget: W) => {
        acc[widget.id] = React.createRef();
        return acc;
      },
      {}
    );
  }, [widgets]);

  const footerSection = widgets.find(
    (widget: W) => widget.id === FOOTER_SECTION_ID
  );
  const footerChildrenIds = useMemo(() => footerSection?.children || [], [
    footerSection,
  ]);
  const headerSection = widgets.find(
    (widget: W) => widget.id === HEADER_SECTION_ID
  );
  const headerChildrenIds = useMemo(() => headerSection?.children || [], [
    headerSection,
  ]);

  const memoizedWidgets = useMemo(() => widgets, [widgets]);

  const getTotalPageHeight = () => {
    const footer = memoizedWidgets.find((w: W) => w.id === FOOTER_SECTION_ID);
    return footer?.y + footer?.height;
  };

  /**
   * Updates the positions of widgets on the page layout.
   *
   * This function recalculates and updates the positions of all widgets based on their original positions,
   * ensuring that they do not overlap and are correctly placed within their parent containers. It also
   * handles the specific layout requirements for header and footer sections, as well as full-width widgets.
   *
   * Additionally, this function relies on a minor layout calculation to widget y positions for handling Banner
   * widgets, ensuring that their positions are adjusted correctly relative to other widgets. This happens in
   * src/client/components/PageLayout/utils.tsx processWidgetData function during the initial widget data processing.
   *
   * The function uses a combination of sorting, recursive updates, and position adjustments to maintain
   * the correct layout. It also takes into account dynamic widget heights and adjusts parent widget heights
   * accordingly.
   *
   * @callback updatePositions
   * @returns {void}
   *
   * @warning This function is critical for maintaining the correct layout of the page and interaction of
   * parent/children widgets.
   * Any changes to this code should be made with extreme caution, as it can have significant
   * impacts on the overall layout and positioning of widgets. Ensure thorough testing of all possible
   * layout scenarios is performed after any modifications. This testing should include validating various
   * parent/child widget configurations, dynamic widget heights, full-width widgets, and header/footer sections.
   */
  const updatePositions = useCallback(() => {
    let shouldUpdate = false;
    // previousBottoms is used to keep track of the bottom-most Y positions of widgets that have already been
    // processed. This helps in determining the new Y positions for subsequent widgets to ensure they
    // do not overlap with the previously placed widgets
    const previousBottoms: PreviousBottoms = {};
    let maxContentBottom = 0;
    let footerY = 0;
    let headerY = 0;
    // Identify all parent widgets and their children
    const parentChildMap: { [key: string]: string[] } = memoizedWidgets.reduce(
      (acc: { [x: string]: string[] }, widget: W) => {
        if (widget.children && widget.children.length > 0) {
          acc[widget.id] = widget.children;
        }
        return acc;
      },
      {} as { [key: string]: string[] }
    );

    // Sort widgets by original Y position to ensure correct processing order
    const sortedWidgets = memoizedWidgets.sort((a: { id: W }, b: { id: W }) => {
      const originalA = originalPositionsRef.current.find(
        (pos: { id: W }) => pos.id === a.id
      )!.originalY;
      const originalB = originalPositionsRef.current.find(
        (pos: { id: W }) => pos.id === b.id
      )!.originalY;
      return originalA - originalB;
    });

    // Recursively update positions of children
    const updateChildPositions = (parentId: string, parentY: number): void => {
      // Find the parent widget and its original Y position
      const parentWidget = updatedWidgets.find(
        widget => widget.id === parentId
      )!;
      const parentOriginalY = originalPositionsRef.current.find(
        (pos: W) => pos.id === parentId
      )!.originalY;
      const parentYDelta = parentY - parentOriginalY;

      // Sort the children of the parent widget by their original Y positions
      const sortedChildren = parentChildMap[parentId]
        .map(childId => updatedWidgets.find(w => w.id === childId))
        .filter(Boolean)
        .sort((a, b) => {
          const originalA = originalPositionsRef.current.find(
            (pos: W) => pos.id === a.id
          )!.originalY;
          const originalB = originalPositionsRef.current.find(
            (pos: W) => pos.id === b.id
          )!.originalY;
          return originalA - originalB;
        });

      let maxChildBottom = parentY;
      let lastDynamicBottom = parentY;
      let dynamicChildHeightChanged = false;
      // Update positions of all child widgets
      sortedChildren.forEach(childWidget => {
        const leftBoundingBoxEdge =
          window.innerWidth >= 1280 ? (window.innerWidth - 1280) / 2 : 0;
        const isHeaderChild = headerChildrenIds.includes(childWidget.id);
        const childElement = widgetRefs.current[childWidget.id]?.current;
        const childHeight = childElement
          ? childElement.offsetHeight
          : childWidget.height;
        const isDynamic = childWidget.dynamic || false;
        const childOriginal = originalPositionsRef.current.find(
          (pos: W) => pos.id === childWidget.id
        )!;

        // Calculate new Y position for the child widget
        let newChildY = isHeaderChild
          ? childOriginal.originalY
          : childOriginal.originalY + parentYDelta;

        if (newChildY < lastDynamicBottom && !isHeaderChild) {
          newChildY = lastDynamicBottom;
        }

        if (newChildY !== childWidget.y) {
          childWidget.y = newChildY;
          shouldUpdate = true;
        }

        if (isDynamic) {
          const heightChange = childHeight - childOriginal.height;
          if (heightChange !== 0 || childOriginal.height === null) {
            dynamicChildHeightChanged = true;
            lastDynamicBottom = newChildY + childHeight;
          }
        }

        maxChildBottom = Math.max(maxChildBottom, childWidget.y + childHeight);

        if (childWidget.fullWidth) {
          childWidget.x = -leftBoundingBoxEdge;
          shouldUpdate = true;
        }

        // If the child has children, update their positions recursively
        if (parentChildMap[childWidget.id]) {
          updateChildPositions(childWidget.id, newChildY);
        }
      });

      const newParentHeight = maxChildBottom - parentY;

      if (
        dynamicChildHeightChanged &&
        newParentHeight !== parentWidget.height &&
        parentWidget.id !== HEADER_SECTION_ID
      ) {
        parentWidget.height = newParentHeight;
        shouldUpdate = true;
      }

      maxContentBottom = Math.max(maxContentBottom, maxChildBottom);

      previousBottoms[
        `${parentWidget.x},${parentWidget.x + parentWidget.width}`
      ] = maxChildBottom;
    };

    // Calculate positions for all non-child widgets to prevent overlap
    const updatedWidgets: WidgetData[] = sortedWidgets.map(
      (widget: WidgetData) => {
        // Calculate the left edge of the bounding box for placing fullwidth widgets, header and footer children
        const leftBoundingBoxEdge =
          window.innerWidth >= 1280 ? (window.innerWidth - 1280) / 2 : 0;
        const element = widgetRefs.current[widget.id]?.current;
        let newHeight = widget.height;

        if (widget.dynamic && element && element.offsetHeight > 0) {
          newHeight = element.offsetHeight;
        }

        let newX = widget.fullWidth ? 0 : widget.x;
        let newY = originalPositionsRef.current.find(
          (pos: W) => pos.id === widget.id
        )!.originalY;

        const isChild = Object.values(parentChildMap).some(children =>
          children.includes(widget.id)
        );

        if (
          widget.id === HEADER_SECTION_ID ||
          widget.id === FOOTER_SECTION_ID
        ) {
          widget.x = 0;
        }

        // Update positions of header and footer children
        const updateHeaderFooterChildrenPositions = (
          widget: W,
          section: W,
          sectionY: number
        ) => {
          const sectionChildrenIds = section.children || [];

          if (sectionChildrenIds.includes(widget.id)) {
            const originalY = originalPositionsRef.current.find(
              (pos: W) => pos.id === widget.id
            )!.originalY;
            const sectionOriginalY = originalPositionsRef.current.find(
              (pos: W) => pos.id === section.id
            )!.originalY;
            const sectionOriginalX = originalPositionsRef.current.find(
              (pos: W) => pos.id === widget.id
            )!.originalX;
            newX = leftBoundingBoxEdge + sectionOriginalX - 5;
            newY = sectionY + (originalY - sectionOriginalY);
            if (newY !== widget.y || newX !== widget.x) {
              shouldUpdate = true;
              if (
                widget.x === sectionOriginalX ||
                !(
                  leftBoundingBoxEdge + sectionOriginalX <
                  leftBoundingBoxEdgeRef.current
                )
              ) {
                widget.x = newX;
              }
              widget.y = newY;
            }
            setFooterUpdated(true);
          }
        };

        // Header/Footer children specific positioning logic
        if (headerSection && footerSection) {
          updateHeaderFooterChildrenPositions(widget, footerSection, footerY);
          updateHeaderFooterChildrenPositions(widget, headerSection, headerY);
        }

        if (
          isChild ||
          widget.id === HEADER_SECTION_ID ||
          widget.id === FOOTER_SECTION_ID
        ) {
          return widget;
        }

        // Using x position, determine which widgets need to move down because they are under another widget that moves down
        for (const [xRange, bottom] of Object.entries(previousBottoms)) {
          const [startX, endX] = xRange.split(',').map(Number);
          const widgetEndX = newX + widget.width;
          // Account for the fact that full width widgets are placed at the left edge of the screen and can have a negative x value
          if ((newX < endX && widgetEndX > startX) || widget.fullWidth) {
            if (newY < bottom) {
              newY = bottom;
            }
          }
        }

        if (
          !headerChildrenIds.includes(widget.id) &&
          !footerChildrenIds.includes(widget.id) &&
          widget.fullWidth
        ) {
          // If it's full width and in the main content area, place it at the left edge of the screen and ensure it spans the full width
          newX = -leftBoundingBoxEdge;
          shouldUpdate = true;
        }

        if (newY !== widget.y || newX !== widget.x) {
          shouldUpdate = true;
          widget.y = newY;
          widget.x = newX;
        }

        previousBottoms[`${newX},${newX + widget.width}`] = newY + newHeight;
        maxContentBottom = newY + newHeight;

        return {
          ...widget,
          height: newHeight,
        };
      }
    );

    // Process header and footer sections but return all other widgets as is
    const headerFooterUpdatedWidgets: WidgetData[] = updatedWidgets.map(
      widget => {
        if (widget.id === HEADER_SECTION_ID) {
          headerY = 0;
          if (headerY !== widget.y) {
            shouldUpdate = true;
            widget.y = headerY;
          }
        } else if (widget.id === FOOTER_SECTION_ID) {
          const originalFooterY = originalPositionsRef.current.find(
            (w: W) => w.id === widget.id
          ).originalY;

          const finalFooterMaxBottom = () => {
            let maxBottom = 0;
            memoizedWidgets.forEach((widget: W) => {
              if (
                widget.id !== FOOTER_SECTION_ID &&
                !footerChildrenIds.includes(widget.id)
              ) {
                const bottom = widget.y + widget.height;
                if (bottom > maxBottom) {
                  maxBottom = bottom;
                }
              }
            });

            return maxBottom;
          };

          maxContentBottom = finalFooterMaxBottom();
          footerY =
            maxContentBottom >= widget.y ? maxContentBottom + 10 : widget.y;
          if (footerY !== widget.y || maxContentBottom < footerY) {
            shouldUpdate = true;
            if (maxContentBottom < footerY && footerY < window.innerHeight) {
              widget.y = window.innerHeight - widget.height + 10;
            } else {
              widget.y = maxContentBottom + 10;
            }
            // Ensure the footer is placed in the original location if content length does not need it to move
            if (widget.y < originalFooterY) {
              widget.y = originalFooterY;
            }
          }
          setFooterUpdated(true);
        }
        return widget;
      }
    );

    // Recursively process children widgets and update their positions
    const finalWidgets: WidgetData[] = headerFooterUpdatedWidgets.map(
      widget => {
        if (parentChildMap[widget.id]) {
          updateChildPositions(widget.id, widget.y);
        }
        return widget;
      }
    );

    // Check if any widget positions have changed and update state if necessary
    const hasChanged = finalWidgets.some(newWidget => {
      const prevWidget = memoizedWidgets.find((w: W) => w.id === newWidget.id);
      return (
        newWidget.x !== prevWidget.x ||
        newWidget.y !== prevWidget.y ||
        newWidget.height !== prevWidget.height ||
        newWidget.children !== prevWidget.children ||
        footerUpdated
      );
    });

    if (shouldUpdate && hasChanged) {
      setWidgets(finalWidgets);
      setIsLayoutUpdated(true);
      setFooterUpdated(false);
    }
  }, [
    memoizedWidgets,
    headerChildrenIds,
    footerChildrenIds,
    widgetRefs,
    initialWidgets,
  ]);

  useEffect(() => {
    const handleResize = () => {
      updatePositions();
      setIsLayoutUpdated(true);
    };

    window.addEventListener('resize', handleResize);
    updatePositions();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updatePositions]);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      requestAnimationFrame(() => updatePositions());
    });

    Object.values(widgetRefs.current).forEach(ref => {
      if (ref.current) {
        mutationObserver.observe(ref.current, {
          childList: true,
          subtree: true,
        });
      }
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [updatePositions]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => updatePositions());
    });

    Object.values(widgetRefs.current).forEach(ref => {
      if (ref.current) {
        resizeObserver.observe(ref.current);
      }
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [updatePositions]);

  useLayoutEffect(() => {
    if (isLayoutUpdated) {
      setIsLayoutUpdated(false);
    }
  }, [isLayoutUpdated]);

  const sectionWidgets = widgets.reduce(
    (acc: { header: W[]; footer: W[]; main: W[] }, widget: W) => {
      if (
        widget.id === HEADER_SECTION_ID ||
        headerChildrenIds.includes(widget.id) ||
        widget.id === HEADER_PLACEHOLDER_ID
      ) {
        acc.header.push(widget);
      } else if (
        widget.id === FOOTER_SECTION_ID ||
        footerChildrenIds.includes(widget.id) ||
        widget.id === FOOTER_PLACEHOLDER_ID
      ) {
        acc.footer.push(widget);
      } else {
        acc.main.push(widget);
      }
      return acc;
    },
    { header: [], main: [], footer: [] }
  );

  const placeElement = (widget: WidgetData) => {
    const fullWidth =
      widget.id === HEADER_SECTION_ID ||
      widget.id === FOOTER_SECTION_ID ||
      widget.fullWidth;
    let isSafariNav = false;
    if (
      isSafari() &&
      widget.type === widgetTypes.Navigation &&
      currentPage.content[widget.id].config.style === 'Horizontal'
    ) {
      isSafariNav = true;
    }
    // Use the initialWidget content in order to maintain accurate widget state without having to perform unnecessary layout calculations
    const initialWidget = initialWidgets.find((w: W) => w.id === widget.id);
    return (
      <Widget
        {...widget}
        className={widget.id}
        key={widget.id}
        ref={widgetRefs.current[widget.id]}
        fullWidth={fullWidth}
        footerChildrenIds={footerChildrenIds}
        headerChildrenIds={headerChildrenIds}
        visible={initialWidget.visible}
        isSafariNav={isSafariNav}
      >
        {initialWidget.content}
      </Widget>
    );
  };
  const { pageBackground } = currentPage;

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: pageBackground ? pageBackground.color : '#FFFFFF',
        height: `${getTotalPageHeight()}px`,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div
        className="header-container"
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        {sectionWidgets.header.map(placeElement)}
      </div>
      <div
        className="main-container"
        style={{
          maxWidth: '1280px',
          width: '100%',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        {sectionWidgets.main.map(placeElement)}
      </div>
      <div
        className="footer-container"
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        {sectionWidgets.footer.map(placeElement)}
      </div>
    </div>
  );
};

export default PageLayout;
