import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTemplateList from './PageTemplateList';
import {
  fetchPageTemplates,
  selectPageTemplates,
  selectPageTemplatesMeta,
} from 'shared/state/ducks/pageTemplates';
import { RootState } from '../../../shared/state';
import { selectUserTenancy } from '../../../shared/state/ducks/tenancies';

const PageTemplates = () => {
  const dispatch = useDispatch();
  const pageTemplates = useSelector((state: RootState) =>
    selectPageTemplates()(state)
  );
  const pageTemplatesMeta = useSelector((state: RootState) =>
    selectPageTemplatesMeta()(state)
  );
  const userTenancy = useSelector((state: RootState) =>
    selectUserTenancy()(state)
  );

  const { error, pending } = pageTemplatesMeta;

  useEffect(() => {
    if (userTenancy) {
      const tenancies = [userTenancy];
      // Todo: Implement pagination TA25100230
      const limit = 100;
      const offset = 0;
      dispatch(fetchPageTemplates(tenancies, limit, offset));
    }
  }, [userTenancy, dispatch]);

  return (
    <PageTemplateList
      pageTemplates={pageTemplates}
      error={error}
      pending={pending}
    />
  );
};

export default PageTemplates;
