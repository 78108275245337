import { Checkbox as MUICheckBox, CheckboxProps } from '@mui/material';
function Checkbox(props: CheckboxProps) {
  return (
    <MUICheckBox
      sx={{ color: '#002677' }}
      disableRipple
      color="default"
      {...props}
    />
  );
}
export default Checkbox;
