import { useRouteMatch } from 'react-router-dom';
import FormTypesListContainer from './FormTypesListContainer';
import FormDetails from './FormDetails';
import Scrollbars from 'react-custom-scrollbars';

const FormRoutes = () => {
  const formDetailMatch = useRouteMatch('/forms/:formId');
  const formListMatch = useRouteMatch('/forms');

  if (formDetailMatch && formDetailMatch.isExact) {
    return (
      <>
        <Scrollbars
          autoHeight
          autoHeightMin="none"
          autoHeightMax="none"
          style={{ padding: '0em 1.5em' }}
        >
          <FormDetails />
        </Scrollbars>
      </>
    );
  } else if (formListMatch && formListMatch.isExact) {
    return (
      <>
        <Scrollbars
          autoHeight
          autoHeightMin="none"
          autoHeightMax="none"
          style={{ padding: '0em 1.5em' }}
        >
          <FormTypesListContainer />
        </Scrollbars>
      </>
    );
  }
  return null;
};

export default FormRoutes;
