import _ from 'lodash';
import { TEMPLATES_SITE_ID } from '../constants/constants';

export function compareLayoutDefinitions(
  obj1: PageContent,
  obj2: PageContent
): boolean {
  return _.isEqual(obj1, obj2);
}

/**
 *
 * Site ID is not defined in redux state
 * This helper function was written instead of adding the site id to redux
 *
 * @param siteId
 * @param path
 * @returns
 */
export function getTemplateSiteID(
  siteId: string | undefined,
  path: string
): string | undefined {
  // siteId is undefined in site builder when user is on template editor
  if (siteId === undefined) {
    // check if user is on template editor
    if (path.includes('page-templates')) {
      return `${TEMPLATES_SITE_ID}`;
    }
  }

  // Default to returning the siteId sent
  return siteId;
}
