import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSiteListDialogOpen,
  closeSiteListDialog,
  selectSiteListDialogSiteId,
  selectSiteListDialogSiteName,
  selectSiteListDialogDialogType,
} from 'shared/state/ducks/ui';
import SiteListDialog from './SiteListDialog';
import {
  unPublishSite,
  archiveSite,
  createPage,
  fetchPages,
} from 'shared/state/ducks/sites';
import { fetchSites } from 'shared/state/ducks/sites';

const mapStateToProps = (state: RootState) => ({
  open: selectSiteListDialogOpen()(state),
  siteId: selectSiteListDialogSiteId()(state),
  siteName: selectSiteListDialogSiteName()(state),
  dialogType: selectSiteListDialogDialogType()(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  unPublishSite: (id: number) => {
    return dispatch(unPublishSite(id));
  },
  fetchPages: (id: number) => {
    dispatch(fetchPages(id));
  },
  archiveSite: (id: number) => {
    dispatch(archiveSite(id));
  },
  closeSiteListDialog: () => {
    dispatch(closeSiteListDialog());
  },
  fetchSites: () => {
    dispatch(fetchSites());
  },
  createPage: (siteId: number, page: Partial<PageVersion>) => {
    return dispatch(createPage(siteId, page));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteListDialog);
