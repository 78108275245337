import { SetStateAction, useEffect, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import styled from 'styled-components';
import useHandleColorSave from 'builder/scenes/SiteBuilder/SiteStylesPanel/useHandleColorSave';
import { defaultAndCustomColors } from 'shared/util/theme';
import { textColor } from 'builder/scenes/SiteBuilder/SiteStylesPanel/SiteStylesPanel';
import { Button } from '@uitk/react';
import { TextField, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { rgbaToHex } from 'shared/util/hexrgb';
import { Add } from '@uitk/react-icons';
import Tooltip from 'shared/components/Tooltip';

export const StyledPickerHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledPickerWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0.5rem;
  .MuiSvgIcon-root {
    display: flex;
    justify-items: flex-end;
    cursor: pointer;
  }
`;

export const StyledPickerPopover = styled(Popover)`
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-family: OptumSans, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 1rem;
  width: 20em;
`;

export const StyledClose = styled(Close)`
  .MuiSvgIcon-root {
    display: flex;
    justify-items: flex-end;
  }
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.75em;
`;

export const ColorPip = styled.div<{ borderColor?: string }>`
  margin: 0.4rem;
  border-radius: 100%;
  border: 1px solid
    ${p => (p.borderColor === '' ? 'transparent' : p.borderColor)};
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  &.color-selected {
    width: 2rem;
    height: 2rem;
  }
`;

export const ColorPipWrapper = styled.span`
  justify-items: center;
  display: flex;
  & #strikethrough {
    position: relative;
    border: 2px solid #e0e0e0;
    border-radius: 100%;
  }
  & #strikethrough:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: -1px;
    border-top: 2px solid;
    border-color: #ef4e63;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export const PaletteWrapper = styled.div`
  padding-left: 0.65em;
  display: flex;
  justify-items: center;
`;

export const DivWrapper = styled.div`
  width: 16em;
  display: flex;
  justify-items: center;
  & div {
    justify-items: center;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0.5rem;
  .uitk-button {
    font-family: OptumSans, sans-serif;
    font-weight: 700;
  }
`;

export const HoverWrapper = styled.div`
  height: 1.5em;
  display: flex;
  justify-content: space-between;
`;

export const HoverItem = styled.div`
  padding: 0 0.8em 0.8em 0.8em;
  height: 1em;
  color: #333333;
  font: OptumSans;
  font-size: 0.8em;
  font-weight: 400;
`;

export const StyledTitle = styled.div`
  margin-left: 0.8em;
  font-size: 0.9em;
  font-weight: 400;
`;

const ColorPickerWrapper = styled.div`
  padding: 0.75rem;
  & > div > div > div > div > input {
    font-family: OptumSans, sans-serif;
  }
`;

const StyledInput = styled(TextField)`
  width: 7rem;
  flex-direction: row;
  justify-items: space-evenly;
  .MuiOutlinedInput-input {
    padding: 0.35rem 0 0.35rem 0.35rem;
    font-family: OptumSans, sans-serif;
    font-size: 0.8rem;
    height: 1.75rem;
  }
  .MuiFormHelperText-root {
    font-family: OptumSans, sans-serif;
    font-size: 0.65rem;
    line-height: 1;
    letter-spacing: 0;
  }
  .MuiFormHelperText-contained {
    margin: 0.3rem 0 0 0;
    position: absolute;
    bottom: 4em;
  }
`;

const SaveColorButton = styled(Button)`
  line-height: 120%;
  font-size: 0.7rem;
`;

export interface GradientColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  site: Site;
  handleColorChange: (color) => void;
  updateSite: (site: Site) => void;
  saveSite: (site: Site) => void;
  anchorEl: HTMLElement | null;
}

export const GradientColorPicker: React.FC<GradientColorPickerProps> = (
  props: GradientColorPickerProps
) => {
  const [hoverName, setHoverName] = useState('');
  const [hoverValue, setHoverValue] = useState('');
  const [currentColor, setCurrentColor] = useState(props?.value);
  const [currentColorName, setCurrentColorName] = useState('');
  const [currentColorType, setCurrentColorType] = useState('');
  const [isValidHex, setIsValidHex] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [nameInputTouched, setNameInputTouched] = useState(false);
  const [colors, setColors] = useState(
    defaultAndCustomColors(props.site?.theme)
  );
  const [saveNewColor, setSaveNewColor] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setColors(() => defaultAndCustomColors(props.site?.theme));
  }, [props.site?.theme]);

  const enableDiv = (e: React.MouseEvent<any>, color) => {
    e.preventDefault();
    if (color === 'transparent') {
      setHoverName('Transparent');
      setHoverValue('');
    } else if (color.value.startsWith('linear')) {
      setHoverName(color.name);
      setHoverValue('Linear Gradient');
    } else if (color.value.startsWith('radial')) {
      setHoverName(color.name);
      setHoverValue('Radial Gradient');
    } else if (color.value.startsWith('rgb')) {
      setHoverName(color.name);
      setHoverValue(rgbaToHex(color.value));
    } else {
      setHoverName(color.name);
      setHoverValue(color.value);
    }
  };

  const disableDiv = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setHoverName('');
    setHoverValue('');
  };

  const handleColorClick = (e: React.MouseEvent<any>, color) => {
    e.stopPropagation();
    e.preventDefault();
    let rgbaHex = color.value;
    if (color.value.startsWith('rgb')) {
      rgbaHex = rgbaToHex(color.value);
    }
    if (color === 'transparent') {
      setCurrentColor('#ffffff00');
      setCurrentColorName('');
      props.handleColorChange('#ffffff00');
    } else {
      setCurrentColor(rgbaHex);
      setCurrentColorName(color.name);
      setCurrentColorType(color.type);
      props.handleColorChange(rgbaHex);
      setIsValidHex(true);
      setIsValidName(true);
    }
  };

  const handleColorFunction = (
    currentColor,
    currentColorName,
    currentColorType,
    props
  ) => {
    useHandleColorSave(
      currentColor,
      currentColorName,
      currentColorType ?? undefined,
      props
    );
  };

  const outlinePip = (color: string) => {
    if (textColor(color) === 'white') {
      return '';
    } else {
      return '#DEDFDF';
    }
  };

  const handleCurrentColorChange = (newColor: SetStateAction<string>) => {
    setSaveNewColor(false);
    setNameInputTouched(false);
    setCurrentColorType(undefined);
    setIsValidHex(true);
    setIsValidName(true);
    setCurrentColor(newColor);
    props.handleColorChange(newColor);
    setCurrentColorName('');
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleNameInput = (e: React.ChangeEvent<any>) => {
    const newName = e.currentTarget.value;
    setCurrentColorName(newName);
    if (newName === '' || newName === ' ') {
      setIsValidName(false);
      setErrorMessage('Name is required');
    } else if (colorNameExists(newName)) {
      setIsValidName(false);
      setErrorMessage('Name already exists');
    } else {
      setIsValidName(true);
      setErrorMessage('');
    }
  };

  const colorNameExists = name => {
    let value = false;
    colors.forEach(color => {
      if (color.name.trim().toLowerCase() === name.trim().toLowerCase()) {
        value = true;
      }
    });
    return value;
  };

  const colorValueExists = value => {
    let exists = false;
    colors.forEach(color => {
      if (
        color.value.startsWith('linear') ||
        color.value.startsWith('radial')
      ) {
        if (rgbaToHex(color.value) === value) {
          exists = true;
        }
      } else if (color.value === value) {
        exists = true;
      }
    });
    return exists;
  };

  const handleCheck = () => {
    setSaveNewColor(!saveNewColor);
    if (!saveNewColor) {
      setIsValidName(true);
      setCurrentColorName('');
      setCurrentColorType('');
    }
  };

  const handleSaveColorPress = () => {
    setSaveNewColor(false);
    handleColorFunction(
      currentColor,
      currentColorName,
      currentColorType,
      props
    );
  };

  return (
    <>
      <ColorPickerWrapper>
        <ColorPicker
          value={currentColor}
          onChange={handleCurrentColorChange}
          hidePresets
          disableDarkMode
        />
      </ColorPickerWrapper>
      <StyledTitle>My Colors:</StyledTitle>
      <DivWrapper>
        <PaletteWrapper data-test-id="color-palette">
          <ColorPipWrapper>
            <ColorPip
              borderColor="transparent"
              id="strikethrough"
              onMouseEnter={e => enableDiv(e, 'transparent')}
              onMouseOut={disableDiv}
              onClick={e => handleColorClick(e, 'transparent')}
              data-test-id="theme-display-circle"
              style={{ background: '#F2F2F2' }}
            />
          </ColorPipWrapper>
          {colors.map(color => (
            <ColorPipWrapper key={color.value}>
              <ColorPip
                id="palette-display"
                borderColor={outlinePip(color.value)}
                onMouseEnter={e => enableDiv(e, color)}
                onMouseOut={disableDiv}
                onClick={e => handleColorClick(e, color)}
                data-test-id="theme-display-circle"
                style={{ background: `${color.value}` }}
              />
            </ColorPipWrapper>
          ))}
          {!colorValueExists(currentColor) && (
            <ColorPipWrapper>
              <Tooltip
                title="Add current color to palette"
                placement="bottom"
                arrow
              >
                <ColorPip
                  borderColor="#DEDFDF"
                  id="add-new-color"
                  onClick={handleCheck}
                  data-test-id="add-new-color-circle"
                  style={{ background: '#F2F2F2' }}
                >
                  <Add size="m" fill="#969696" />
                </ColorPip>
              </Tooltip>
            </ColorPipWrapper>
          )}
        </PaletteWrapper>
      </DivWrapper>

      {saveNewColor && !colorValueExists(currentColor) && (
        <InputWrapper onClick={handlePickerClick}>
          <ColorPip
            className="color-selected"
            id="color-selected"
            borderColor="#DEDFDF"
            data-test-id="color-display-circle"
            style={{ background: `${currentColor}`, cursor: 'default' }}
          />
          <StyledInput
            data-test-id="color-name-input"
            variant="outlined"
            value={currentColorName}
            onChange={handleNameInput}
            placeholder="Name"
            onChangeCapture={() => setNameInputTouched(true)}
            error={
              !isValidName || (nameInputTouched && currentColorName === '')
            }
            helperText={errorMessage}
          />
          <ButtonWrapper>
            <SaveColorButton
              data-test-id="save-new-color-button"
              disabled={!isValidHex || !isValidName || currentColorName === ''}
              onPress={handleSaveColorPress}
            >
              Save to My Colors
            </SaveColorButton>
          </ButtonWrapper>
        </InputWrapper>
      )}

      {!saveNewColor && (
        <HoverWrapper>
          <HoverItem>{hoverName}</HoverItem>
          <HoverItem>{hoverValue}</HoverItem>
        </HoverWrapper>
      )}
    </>
  );
};

export default GradientColorPicker;
