import { Config } from './types';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
} from 'builder/util/constants';
import AccordionWidgetContainer from './AccordionWidgetContainer';
import AccordionWidgetControl from './AccordionWidgetControl';

export default ({
  type: 'AccordionWidget',
  displayName: 'Accordion',
  icon: 'list',
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultBorderData,
    width: 800,
    height: 350,
    xLocation: 10,
    yLocation: 10,
    uniqueId: 'acc345',
    lineColor: '#828485',
    accordionPanels: [
      {
        id: 0,
        name: 'Panel 1',
        required: false,
        content: 'panel 1 content',
      },
      {
        id: 1,
        name: 'Panel 2',
        required: false,
        content: 'Panel 2 content',
      },
      {
        id: 2,
        name: 'Panel 3',
        content: 'Panel 3 content',
      },
    ],
    currentTabIndex: -1,
    hoverFocusColor: '#ff612b',
    accordionConnected: true,
    textColor: '#333333',
    fontSize: 14,
    font: 'OptumSans',
    visible: true,
  },
  components: {
    render: AccordionWidgetContainer,
    control: AccordionWidgetControl,
  },
  copyable: true,
} as unknown) as HorizonWidgetDefinition<Config>;
