import { Fragment, useEffect } from 'react';
import { History } from 'history';
import styled from 'styled-components';
import userManager from 'builder/util/userManager';
import { Header } from '@uitk/react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Footer,
  NavigationLink,
  NavigationLinkList,
  Sidebar,
  defaultTheme as theme,
} from 'link-ui-react';
import { Link } from 'react-router-dom';
import { User } from 'oidc-client';
import SiteDataTable from 'shared/components/SiteDataTable';
import config from 'builder/config';
import FormRoutes from 'shared/components/FormTable/FormRoutes';
import PageTemplatesRoutes from 'builder/scenes/PageTemplates/PageTemplatesRoutes';
import {
  StelliumData,
  stelliumRedirectionUrl,
} from 'shared/util/stelliumRedirection';
import {
  IconForms,
  IconUsersGroup,
  IconBrandTeams,
  IconLifebuoy,
  IconSubtask,
  IconHome,
  IconBuildingCarousel,
  IconLogout2,
} from '@tabler/icons-react';
import env from 'builder/config';

const OptumLogo = (
  <img
    src={
      'https://cdn-stage.linkhealth.com/site-builder/horizon/optumOrangeLogo.png'
    }
    alt="optum-horizon-logo"
    width={100}
  />
);

const LogoutWrapper = styled.div`
  color: #d00606;
  padding: 1rem;
  display: inline-flex;
  width: 100%;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
`;
const StyledInnerSideBarWrapper = styled.div`
  min-height: 88vh;
`;
const LogoutText = styled.div`
  margin-left: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: OptumSans, sans-serif;
`;

const SideBarLabel = styled.div`
  margin-left: 1em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
`;

const StyledBanner = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
`;

const SiteListWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 82%;
  flex-direction: column;
`;

const ImageAndTextWrapper = styled.div`
  position: relative;
  margin-bottom: 2em;
`;

const StyledGreetingsMessage = styled.div`
  font-size: 1.75em;
  font-family: OptumSans, sans-serif;
  font-weight: 700;
  color: ${theme.colors.aux.darkNavyBlue};
  position: absolute;
  left: 3%;
  bottom: 60%;
`;

const StyledHeader = styled(Header)`
  z-index: 0;
  & a {
    padding-left: 0.15rem;
  }
`;

const StyledWelcome = styled.div`
  font-size: 1.75em;
  font-family: OptumSans, sans-serif;
  font-weight: 700;
  color: ${theme.colors.aux.darkNavyBlue};
  position: absolute;
  left: 3%;
  bottom: 38%;
`;

const StyledTitle = styled.div`
  font-size: 1em;
  font-family: OptumSans, sans-serif;
  font-weight: 400;
  position: absolute;
  left: 3%;
  bottom: 20%;
`;

const StyledLoggedInUserName = styled.span`
  color: ${p => p.theme.colors.aux.portlandOrange};
`;

const IconNavigationLink = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 0.75em;
  ${({ isActive }) =>
    isActive &&
    `
  font-weight: bold;
  border-bottom: 1px solid rgb(209 213 216 / 32%);
  background-color: #f9f4ec;
  border-left: 0.25em solid #c25608;
`}
 li {
  border-bottom: none;
  background-color: transparent;
  border-left: none;
  box-shadow: none !important;
  background-color: transparent !important;
  outline: none !important;
  flex-basis: 100%;
    & > a > div {
      font-family: OptumSans, sans-serif;
    }
  }
  &:active,
  &:focus,
  &:hover {
    font-weight: bold;
    opacity: 0.9;
    background-color: #c25608;
    fill: #ffffff;
    color: #ffffff;
    box-shadow: inset 0 0 0 4px #ffffff;
    outline: #c25608 solid;
    outline-offset: -2px;
    & > svg {
      stroke: white;
  }
`;

/* Used these RoutableLink, verticalNavigationConfig to mockup the Sidebar , it should be removed/modified after implementing actual Sidebar */

export interface SiteListProps {
  user: User;
  sites: Array<Site>;
  history?: History;
  initiateLogout: () => void;
  createPermission: boolean;
  openCreateSiteDialog: () => void;
  publishSite?: (siteId: number) => Promise<void>;
  fetchSites?: () => void;
  fetchSitesByUuid?: () => void;
  fetchSite?: (siteId: number, openDialog: boolean) => void;
  fetchUserTenancy: () => Promise<string>;
  openSiteListDialog?: (
    dialogType: string,
    siteId: number,
    siteName: string
  ) => void;
  builderPermissions?: string[];
  userTenancy: string;
}

export const SiteList: React.FC<SiteListProps> = (props: SiteListProps) => {
  const {
    user,
    history,
    sites,
    userTenancy,
    openCreateSiteDialog,
    publishSite,
    fetchSitesByUuid,
    fetchUserTenancy,
    openSiteListDialog,
    fetchSite,
  } = props;

  const serviceNowUrl =
    'https://optum.service-now.com/itss2/?id=sc_cat_item&sys_id=7205f23e1bdee0107eadec27624bcb41';
  const teamsChannelUrl =
    'https://teams.microsoft.com/l/channel/19%3Ac9DnswCLKWfTrfiOcA-wiGXFRfWpSc4ef8TStfklC-g1%40thread.tacv2/General?groupId=6a9926b0-14a4-43c9-bba8-66b79f720065&tenantId=';
  const enhancementsUrl = 'https://userexperience.ideas.aha.io/';

  useEffect(() => {
    if (fetchUserTenancy) {
      fetchUserTenancy();
    }
  }, [fetchUserTenancy]);

  const userManagementUrl =
    config.userManagementBaseUrl + `/${userTenancy}/users`;

  const hasGodRoleBusinessKey = () => {
    return !!props.builderPermissions?.length;
  };

  const handleLogOut = () => {
    const { initiateLogout } = props;
    initiateLogout();
    userManager.signoutRedirect({
      post_logout_redirect_uri: window.location,
    });
  };

  const getGreetingsMessage = () => {
    const today = new Date();
    const currentHour = today.getHours();
    if (currentHour < 12) {
      return 'Good morning, ';
    } else if (currentHour < 18) {
      return 'Good afternoon, ';
    } else {
      return 'Good evening, ';
    }
  };

  const manageAppsLink = () => {
    const stelliumSessionData: StelliumData = {
      siteIds: sites.filter(site => !site.archived).map(site => site.id),
      tenantId: userTenancy,
      askId: '',
    };
    return stelliumRedirectionUrl(stelliumSessionData);
  };

  const formSubmissionsLabel = (
    <p style={{ fontFamily: 'OptumSans' }}>Form Submissions</p>
  );
  const customTemplatesLabel = (
    <p style={{ fontFamily: 'OptumSans' }}>Custom Templates</p>
  );
  const loggedInUserName = user.profile.name;
  const loggedInUserFirstName = loggedInUserName.substring(
    0,
    loggedInUserName.indexOf(' ')
  );

  return (
    <Wrapper>
      <Sidebar>
        <StyledInnerSideBarWrapper>
          <StyledHeader
            logoContent={OptumLogo}
            productName={'Horizon'}
            productLink={'sites'}
          />
          <NavigationLinkList>
            <IconNavigationLink isActive={location.pathname.includes('/sites')}>
              <IconHome size={24} stroke={1} color="#c25608" />
              <NavigationLink
                isActive={location.pathname.includes('/sites')}
                label={'Home'}
                linkComponent={Link}
                linkProps={{ to: '/sites' }}
              />
            </IconNavigationLink>
          </NavigationLinkList>
          <SideBarLabel>Admin Tools</SideBarLabel>
          <NavigationLinkList>
            <IconNavigationLink isActive={location.pathname.includes('/forms')}>
              <IconForms size={24} stroke={1} color="#c25608" />
              <NavigationLink
                isActive={location.pathname.includes('/forms')}
                label={formSubmissionsLabel}
                linkComponent={Link}
                linkProps={{ to: '/forms' }}
              />
            </IconNavigationLink>
            {env.enableTemplate && (
              <IconNavigationLink
                isActive={location.pathname.includes('/page-templates')}
              >
                <IconForms size={24} stroke={1} color="#c25608" />
                <NavigationLink
                  isActive={location.pathname.includes('/page-templates')}
                  label={customTemplatesLabel}
                  linkComponent={Link}
                  linkProps={{ to: '/page-templates' }}
                />
              </IconNavigationLink>
            )}
          </NavigationLinkList>
          <SideBarLabel>User Management</SideBarLabel>
          <NavigationLinkList>
            <IconNavigationLink isActive={false}>
              <IconUsersGroup size={24} stroke={1} color="#c25608" />
              <NavigationLink
                label="Manage Users"
                openInNewTab
                linkProps={{ href: userManagementUrl, target: '_blank' }}
              />
            </IconNavigationLink>
          </NavigationLinkList>
          <SideBarLabel>Site Management Tools</SideBarLabel>
          <NavigationLinkList>
            {hasGodRoleBusinessKey() && (
              <IconNavigationLink isActive={false}>
                <IconBuildingCarousel size={24} stroke={1} color="#c25608" />
                <NavigationLink
                  label="Manage Applications"
                  openInNewTab
                  linkProps={{ href: manageAppsLink(), target: '_blank' }}
                />
              </IconNavigationLink>
            )}
          </NavigationLinkList>
          <Fragment>
            <SideBarLabel>Support</SideBarLabel>
            <NavigationLinkList>
              <IconNavigationLink isActive={false}>
                <IconLifebuoy size={24} stroke={1} color="#c25608" />
                <NavigationLink
                  label="ServiceNow"
                  openInNewTab
                  linkProps={{ href: serviceNowUrl, target: '_blank' }}
                />
              </IconNavigationLink>
              <IconNavigationLink isActive={false}>
                <IconBrandTeams size={24} stroke={1} color="#c25608" />
                <NavigationLink
                  label="Teams Channel"
                  openInNewTab
                  linkProps={{ href: teamsChannelUrl, target: '_blank' }}
                />
              </IconNavigationLink>

              <IconNavigationLink isActive={false}>
                <IconSubtask size={24} stroke={1} color="#c25608" />
                <NavigationLink
                  label="Feature Request"
                  openInNewTab
                  linkProps={{ href: enhancementsUrl, target: '_blank' }}
                />
              </IconNavigationLink>
            </NavigationLinkList>
          </Fragment>
        </StyledInnerSideBarWrapper>
        <LogoutWrapper
          data-test-id="logout-button"
          onClick={handleLogOut}
          title="Sign out of Horizon"
        >
          <IconLogout2 size={24} stroke={1} color="#c25608" />
          <LogoutText>Logout</LogoutText>
        </LogoutWrapper>
      </Sidebar>
      <SiteListWrapper>
        <Scrollbars>
          <ImageAndTextWrapper>
            <StyledBanner
              src={
                'https://cdn-stage.linkhealth.com/site-builder/horizon/HorizonBackGroundImage.png'
              }
              alt="duplo-banner"
            />
            <StyledGreetingsMessage>
              {getGreetingsMessage()}
              {
                <StyledLoggedInUserName>
                  {loggedInUserFirstName}
                </StyledLoggedInUserName>
              }
              .
            </StyledGreetingsMessage>
            <StyledWelcome> Welcome to Horizon.</StyledWelcome>
            <StyledTitle> Where design and technology meet. </StyledTitle>
          </ImageAndTextWrapper>
          {location.pathname.includes('/sites') && (
            <SiteDataTable
              sitesData={sites.filter(site => !site.archived)}
              history={history}
              openCreateSiteDialog={openCreateSiteDialog}
              publishSite={publishSite}
              fetchSitesByUuid={fetchSitesByUuid}
              openSiteListDialog={openSiteListDialog}
              fetchSite={fetchSite}
            />
          )}
          {location.pathname.includes('/forms') && <FormRoutes />}
          {location.pathname.includes('/page-templates') && (
            <PageTemplatesRoutes />
          )}
          <Footer />
        </Scrollbars>
      </SiteListWrapper>
    </Wrapper>
  );
};
export default SiteList;
